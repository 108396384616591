<template>
  <v-row>
    <!-- Advanced usage  -->
    <v-col md="12">
      <base-card>
        <v-card-title>Liste den Klassen</v-card-title>
        <v-card-text>
          <v-list
            two-line
            subheader
          >
            <v-list-item
              v-for="course in courses"
              :key="course.id"
              :href="course.link"
            >
              <v-list-item-avatar>
                <v-icon
                  :class="[course.iconClass]"
                  v-text="course.icon"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="course.title" />
                <v-list-item-subtitle v-text="course.subtitle" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {

    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Courses',
    },
    data () {
      return {
        courses: [
          {
            icon: 'mdi-folder',
            iconClass: 'grey lighten-1 white--text',
            subtitle: 'KV Dienstleistung & Administration',
            title: 'Administration & Handel',
            link: 'students-list-pma',
          },
          {
            icon: 'mdi-folder',
            iconClass: 'blue lighten-1 white--text',
            subtitle: 'KV Dienstleistung & Administration',
            title: 'Administration',
            link: 'students-list-pma',
          },
          {
            icon: 'mdi-folder',
            iconClass: 'green lighten-1 white--text',
            subtitle: 'KV Dienstleistung & Administration',
            title: 'Wirtschaft und Gesellschaft',
            link: 'students-list-pma',
          },
        ],
      }
    },
  }
</script>
